import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StatusCode = styled.div`
  color: #D3D3D3;
  font-size: 10em;
  @media (max-width: 620px) {
    font-size: 20em;
    font-size: 40vw;
  }
`;

const Info = styled.div`
  color: #D3D3D3;
  font-size: 1.5em;
  @media (max-width: 620px) {
    font-size: 1em;
    font-size: 5vw;
  }
`;

const Error = (): JSX.Element =>
  <Container>
    <StatusCode>404</StatusCode>
    <Info>Page not found.</Info>
  </Container>;

export default Error;
